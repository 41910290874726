.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 32px;
    gap: 32px;
    isolation: isolate;
    width: 100%;
    height: 80px;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    border-bottom: gray 1px solid;
}

.brand {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    /* gap: 24px; */
    width: 301px;
    height: 40px;
    margin-left: 16px;
    /* margin-top:20px; */
}

.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 40px 0px 0px;
    width: 124px;
    height: 40px;
    background-image: url('../../assets/Logo.png');
}

.brand-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 177px;
    height: 40px;

}

.project-name {
    display: flex;
    /* 
flex-direction: row;
align-items: flex-start; */
    text-align: justify;
    padding: 0px;
    gap: 8px;

    width: 177px;
    height: 40px;
}


.profile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* padding: 0px 0px 0px 16px; */

    float: right;
    width: 48px;
    height: 40;
    /* 
    margin-top: 5px; */
}

.avtar-icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    isolation: isolate;

    height: 40px;
    /* border-radius: 16px; */
}

#super {
    vertical-align: super;
    font-size: 10px;
}

.title-name {
    font-family: 'ABBvoice';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #1F1F1F;
    flex: none;
    width: 186px;
    display: flex;
    flex-direction: column;
}

li a {
    color: black;
    text-transform: capitalize;
}

.sub-title-name {
    font-family: 'ABBvoice';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #1F1F1F;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.profile .css-1llg279-menu {
    position: absolute !important;
    width: auto !important;
    margin-left: -50px !important
}