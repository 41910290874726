.table-style {
  width: 100%;
  position: relative;
  height: 200px !important;
  background: #FFFFFF;
}

.overlay-dr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.dialog-container {
  max-width: 80%;
  /* Adjust the maximum width as needed */
}

#dr-list-table td {
  align-items: center;
  /* border-bottom: 1px solid transparent; */
  display: flex;
  padding-left: 13px !important;
  padding-right: 0px !important;
  position: relative;
}

.dr-filename {
  font-size: 12px;
}

.dist-records-rows {
  cursor: pointer;
  color: blue;
}

.ABB_CommonUX_Datagrid__root>table>thead>tr>th {
  align-items: center;
  border-bottom: 1px solid transparent;
  display: flex;
  font-family: ABBVoice-medium;
  font-size: 12px;
  font-style: normal;
  height: 40px;
  line-height: 1;
  max-height: 40px;
  min-height: 40px;
  overflow: hidden;
  padding: 8px 0px;
  position: relative;
  text-transform: uppercase;
  top: 0;
  -webkit-user-select: none;
  user-select: none;
}

.table-style .ABB_CommonUX_Datagrid__smallRowHeight thead {
  grid-template-columns: 0.6fr 0.65fr 0.85fr 1.2fr !important
}

.table-style .ABB_CommonUX_Datagrid__smallRowHeight tbody {
  grid-template-columns: 0.73fr 0.85fr 1.07fr 1.2fr !important
}

.open-window .ABB_CommonUX_Icon__icon_abb_16 {
  font-size: 14px !important;
}

.acknowledged {
  color: #0CA919;
}


.charged {
  color: #0CA919;
  margin: 0px 5px;
}

.waiting {
  color: #a6a813;
  margin: 0px 5px;
}

.ABB_CommonUX_Button__root.ABB_CommonUX_Button__smallSize>div>span.ABB_CommonUX_Button__text.ABB_CommonUX_Button__hasText.ABB_CommonUX_Button__hasIcon {
  margin-left: 30px !important
}

/* 
#ack-button-setting.ABB_CommonUX_Button__root.ABB_CommonUX_Button__smallSize>div>span.ABB_CommonUX_Button__text.ABB_CommonUX_Button__hasText.ABB_CommonUX_Button__hasIcon {
  margin-left: 30px !important;

} */

#ack-button-setting {
  max-width: 100px;
}





.ABB_CommonUX_Button__root.ABB_CommonUX_Button__smallSize {
  height: 32px;
  width: 123px;
}

.ABB_CommonUX_Dialog__header {
  text-align: left !important;
}

.unack {
  color: #0CA919;
}

.dr-confim-ack {
  z-index: 10 !important;
  position: absolute !important
}

.noteCaesML {
  text-align: left;
}

/* Extra Large screens 150 % zoom*/
@media (max-width: 1440px) {
  .table-style .ABB_CommonUX_Datagrid__smallRowHeight thead {
    grid-template-columns: 1.21fr 0.9fr 1.2fr 1.2fr !important;
  }

  .table-style .ABB_CommonUX_Datagrid__smallRowHeight tbody {
    grid-template-columns: 1.21fr 0.9fr 1.2fr 1.1fr 1.1fr !important;
  }

}

/* 
#ack-button-setting.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__ghost {
  background-color: hsla(0, 0%, 100%, .05);
  border: none !important;
  color: #1f1f1f;

} */

/* 
#ack-button-dr-list.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__ghost {
  width: 70px !important;
} */

.dialog-container .ABB_CommonUX_Button__root>div>span.ABB_CommonUX_Button__text {
  line-height: 16px;
  text-align: center;
  padding-top: 0px;

}

.dialog-container .ABB_CommonUX_Button__root.ABB_CommonUX_Button__smallSize {
  height: 32px;
  width: auto !important;
}

.dialog-container .ABB_CommonUX_IconButton__root.ABB_CommonUX_Dialog__closeButton.ABB_CommonUX_IconButton__clickable.ABB_CommonUX_IconButton__light.ABB_CommonUX_IconButton__large {
  display: block;
}


.dialog-container-dr-ack {
  width: 500px !important;
  height: 300px;
  position: absolute !important;

  top: 16% !important;
  left: 37% !important;
  display: flex !important;
  flex-direction: column !important;
  /* background-color: #3498db; */
  z-index: 100 !important;
}

.dialog-container {
  width: 500px !important;
  height: auto;
  position: absolute !important;

  top: 16% !important;
  left: 37% !important;
  display: flex !important;
  flex-direction: column !important;
  /* background-color: #3498db; */
  z-index: 100 !important;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dialog-section-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dialog-section-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dialog-content span {
  padding-top: 10px;
}

.dr-reason {
  padding-top: 0px;
  font-size: 14px;
  font-weight: 600;
}

.charged {
  color: #0CA919;
  margin: 0px 5px;
}

.dialog-container .ABB_CommonUX_Dialog__header {
  text-align: left;
}

.dialog-container .ABB_CommonUX_Dialog__standardButtonsOnBottom {
  justify-content: flex-start;
}