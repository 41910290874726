.sld-container-title {
    display: flex;
    /* align-items: center; */
    /* padding-top: 8px; */
}



p {
    flex: 1;
}

.sld-iframe .relay-time-update {
    font-size: 12px;
    font-weight: 400;
}

.relay-id {
    font-size: 12px;
    font-weight: 500;
}

.relay-title {
    font-size: 14px;
    font-weight: 700;
}

.sld-captions {
    color: #000 !important;
    font-size: 14px;
    font-weight: 500;
    /* margin-left: 25px; */

}


.sld-common-class {
    display: flex;
    flex-direction: column;
}

.sld-container hr {

    border: 1px solid #000;
    margin-top: 0px;
    opacity: 1 !important;

}

.sld-chart-title {
    /* padding: 8px 4px 0px 32px; */
    font-family: 'ABBvoice';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    /* display: flex; */
    text-transform: uppercase;
    text-align: left;


}

.sld-time-update {
    flex: 1;
    text-align: right;
    /* padding-right: 20px; */
    font-size: 12px;
    font-weight: 400;
    /* padding: 8px 32px 0px 0px; */

}

.ABB_CommonUX_Popup__root.ABB_CommonUX_Popup__hasPaddings {
    padding: 8px;
    min-width: 180px;
}

.sld-iframe {
    max-height: 325px !important;
    max-width: 800px !important;
    overflow: auto !important;
    /* cursor: grab; */
}

.sld-container {
    /* margin-top: 8px; */
    background: #FFFFFF;
    height: 558px !important;
    padding: 32px;
}


.first-col {
    float: left;

}


.zoom-controls {
    float: left;
    z-index: 99 !important;
}

.gateway-status-popup-container {
    margin-left: 60px !important;
}

.sld-pdf-container {
    width: auto;
    height: 100px !important;
}

.pdf-controls-container {
    display: flex;
    justify-content: space-between;
}

.full-screen-controls {
    float: right;
}

.second-col {
    float: right;
    /* text-align: right; */
}

.sld-body {
    text-align: left;

}

#sld-info-table {
    font-size: 14px;
    width: 100%;
}

#sld-info-table .failed {
    color: #F03040;

    margin-right: 0px;
    margin-left: 4px;
}

#change-sld-link {
    color: #0052FF;
    font-size: 14px;
    font-weight: 500;
}

#change-sld-link-icon {
    color: #0052FF;
}

/* 
.widgets-container {
    background-color: white;
} */




#ui-container {
    display: none !important;
}


/* Extra Large screens 100 % zoom*/

@media (max-width: 1920px) {
    #sld-info-table .first-col {
        text-align: left;
        /* padding-top: 16px; */
    }
}

/* Extra Large screens 125 % zoom*/

@media only screen and (max-width: 1536px) {
    .sld-top-info {
        display: flex;
    }

    #sld-info-table .first-col {
        text-align: left;
        padding-top: 0px;
    }

    #sld-info-table .second-col {
        padding-left: 30px;
    }
}

/* Extra Large screens 150 % zoom*/
@media (max-width: 1440px) {
    .sld-top-info {
        display: flex;
    }

    .first-col {
        float: none;
        width: 50%;

    }

    .second-col {
        float: none;
        width: 50%;
    }

    #sld-info-table .first-col {
        text-align: left;
        /* padding-top: 16px; */
    }

    #sld-info-table .second-col {
        padding-left: 0px;
    }


}