.ABB_CommonUX_DonutChart__legend {
    align-content: flex-start;
    flex: 1 0;
    flex-direction: column;
    flex-wrap: wrap;
    /* margin-left: 24px; */
    margin: auto 24px;
}
.alarm-sum-chart{
        width: 100%;
        padding: 20px 0px;
        margin: 0px 20px
}