.chart-card {
    margin: 0px 0px 8px 0px;
    padding: 16px;
    min-height: 275px !important;
    flex-direction: column;
    justify-content: left;
    background: #FFFFFF;
}

.chart-header {

    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.chart-card hr {
    border: 1px solid #000;
    opacity: 1 !important;
    margin: 0.6rem 0 !important
}

/* .chart-title {
   
    padding: 4%;
    height: 14px;
    left: 32px;
    right: 258px;
    top: 35px;
    font-family: 'ABBvoice';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
   

    display: flex;
   
    text-transform: uppercase;

    color: #1F1F1F;
} */

.chart-title {
    font-family: 'ABBvoice';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    display: flex;
    text-transform: uppercase;
    color: #1F1F1F;
}

.card-update-notification {
    font-weight: 400;
    font-size: 12px;
    display: flex;
    text-align: right;
    color: #686868;
}

.card-header-divider {
    border: 1px solid red;
}

.card-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 14px;
    height: 80%;
}

.card-content-scroll {
    overflow-y: scroll;

}

.card-content-scroll .ABB_CommonUX_Table__root {
    width: 100%;
}

hr {
    border: 1px solid black;
    margin: 0 32px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.card-content-even-align {
    justify-content: space-evenly;
}

.event-log-chart-header-update {
    padding-left: 3%;
}

.ABB_CommonUX_Table__root.ABB_CommonUX_Table__small>tbody>tr>td,
.ABB_CommonUX_Table__root.ABB_CommonUX_Table__small>tbody>tr>th {
    padding: 8px 0px;
    line-height: 22px;
}

.ABB_CommonUX_Datagrid__root>table>tbody>tr>td {
    padding: auto 0px;
}


.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>a>.ABB_CommonUX_Gauge__label,
.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg>g>text.ABB_CommonUX_Gauge__label {
    font-size: 24px;
    alignment-baseline: auto;
}

.ABB_CommonUX_Gauge__root.ABB_CommonUX_Gauge__radial>svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    overflow: visible;
}


.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart {
    display: block;
    flex: 1 0;
    max-height: 100%;
    max-width: 100%;
    min-height: 96px;
    min-width: 96px;
    overflow: hidden;
    margin: auto 0px;
}

.ABB_CommonUX_DonutChart__root>.ABB_CommonUX_DonutChart__chart>svg {
    height: 100%;
    left: 0;
    overflow: visible;
    top: 0;
    width: 100%;
    max-width: 124px;
    max-height: 124px;
}