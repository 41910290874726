/* .App {
  text-align: center;
  background: #EBEBEB;
} */
.App {
  text-align: center;
  /* background: #EBEBEB; */
  float: left;
  width: 100%;
  min-height: 100%;
  position: fixed;
}

.main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  float: left;
  width: 100%;
  height: auto;
  position: relative;
  overflow-y: scroll;
  max-height: 100vh;

}


.failed {
  color: #F03040;
  margin: 0px 5px;
}

.center-text-error {
  /* display: flex; */
  padding-top: 75px;
  align-items: center;
}

* {
  box-sizing: border-box;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 10px;
}

/* For mobile phones: */
[class*="col-"] {
  width: 100%;
}


@media only screen and (min-width: 640px) {

  /* For tablets: */
  .col-s-1 {
    width: 8.33%;
  }

  .col-s-2 {
    width: 16.66%;
  }

  .col-s-3 {
    width: 25%;
  }

  .col-s-4 {
    width: 33.33%;
  }

  .col-s-5 {
    width: 41.66%;
  }

  .col-s-6 {
    width: 50%;
  }

  .col-s-7 {
    width: 58.33%;
  }

  .col-s-8 {
    width: 66.66%;
  }

  .col-s-9 {
    width: 75%;
  }

  .col-s-10 {
    width: 83.33%;
  }

  .col-s-11 {
    width: 91.66%;
  }

  .col-s-12 {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {

  /* For tablets: */
  .col-m-1 {
    width: 8.33%;
  }

  .col-m-2 {
    width: 16.66%;
  }

  .col-m-3 {
    width: 25%;
  }

  .col-m-4 {
    width: 33.33%;
  }

  .col-m-5 {
    width: 41.66%;
  }

  .col-m-6 {
    width: 50%;
  }

  .col-m-7 {
    width: 58.33%;
  }

  .col-m-8 {
    width: 66.66%;
  }

  .col-m-9 {
    width: 75%;
  }

  .col-m-10 {
    width: 83.33%;
  }

  .col-m-11 {
    width: 91.66%;
  }

  .col-m-12 {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {

  /* For desktop: */
  .col-1 {
    width: 8.33%;
  }

  .col-2 {
    width: 16.66%;
  }

  .col-3 {
    width: 25%;
  }

  .col-4 {
    width: 33.33%;
  }

  .col-5 {
    width: 41.66%;
  }

  .col-6 {
    width: 50%;
  }

  .col-7 {
    width: 58.33%;
  }

  .col-8 {
    width: 72.66%;
  }

  .col-9 {
    width: 75%;
  }

  .col-10 {
    width: 83.33%;
  }

  .col-11 {
    width: 91.66%;
  }

  .col-12 {
    width: 100%;
  }
}