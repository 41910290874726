.pdf-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    height: 500px !important;
}

.controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

button {
    margin: 0 5px;
    padding: 10px 15px;
    cursor: pointer;
}

.page-info {
    margin-left: 10px;
}

.first-col {
    text-align: left;
    padding-top: 16px;
}

.page-continer-pdf {
    height: 500px !important;
}

.historical-report-table {
    width: 834px;
    position: relative;
    height: 195px !important;
    background: #FFFFFF;
    height: 350px !important;
    /* font-size: small */
}

.date-error {
    padding-top: 16px;
    text-align: left;
    color: crimson;
}

.generate-report-header {
    padding-top: 16px;

    text-align: left;

}

.historical-report-table .ABB_CommonUX_Datagrid__smallRowHeight thead {
    grid-template-columns: 2.4fr 1.4fr 1fr 1.2fr !important
}

.historical-report-table .ABB_CommonUX_Datagrid__smallRowHeight tbody {
    grid-template-columns: 2.4fr 1.4fr 1fr 1.2fr !important
}

.hr-refresh{
    margin: 10px;
}

.hr-refresh .ABB_CommonUX_Link__content {
    text-decoration: none !important;
}