.table-style-alarm-noti {
    width: 100%;
    position: relative;
    height: 200px !important;
    background: #FFFFFF;
}

.ABB_CommonUX_Link__content {
    text-decoration: underline !important;
}

.ABB_CommonUX_Datagrid__root.ABB_CommonUX_Datagrid__light.ABB_CommonUX_Datagrid__borderStyleNormal>table>tbody {
    border-bottom-color: #FFFFFF !important;
}

.table-style-alarm-noti .ABB_CommonUX_Datagrid__smallRowHeight thead {
    grid-template-columns: 0.7fr 1fr 1.3fr !important
}

.table-style-alarm-noti .ABB_CommonUX_Datagrid__smallRowHeight tbody {
    grid-template-columns: 0.7fr 1fr 1.3fr !important
}