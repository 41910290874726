.signup-container {
    display: flex;
    flex-direction: row;
    color: black;
}

.drop-ops-area {
    color: black;
}

.submitSignupform .ABB_CommonUX_Input__inputElement {
    color: black;
}

.signup-dialog-container {
    width: 500px !important;
    height: auto;
    position: absolute !important;

    top: 250px !important;
    left: 35% !important;
    display: flex !important;
    flex-direction: column !important;
    /* background-color: #3498db; */
    z-index: 100 !important;
}

.verify-dialog-container {
    width: 33% !important;
    height: auto;
    position: absolute !important;
    top: 250px !important;
    left: 33% !important;
    display: flex !important
;
    flex-direction: column !important;
    /* background-color: #3498db; */
    z-index: 100 !important;
}

.verify-dialog-container .askverify{
text-align: left;
margin-bottom: 10px;
}

.verify-dialog-container .actionhead{
    font-weight: 600;
}

.verify-dialog-container .actionnote{
    color: red;
    text-align: left;
    font-weight: 500;}
/* 
.dialog-container .ABB_CommonUX_Dialog__header {
    text-align: left;
}

.dialog-container .ABB_CommonUX_Dialog__standardButtonsOnBottom {
    justify-content: flex-start;
} */

.ABB_CommonUX_Input__label {
    margin-top: 5px;
    width: 300px;
}

.ABB_CommonUX_Dialog__closeButton {
    display: none;
}

.ABB_CommonUX_LoginScreen__bottom {
    padding-top: 20px;
}

#role #react-select-3-placeholder {
    color: black;
}

.error-display {
    color: red;
}

.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light.ABB_CommonUX_Input__isEmpty>.ABB_CommonUX_Input__inputWrapper {
    color: black;
}

.signup-container-box {
    width: 300px;
    /* max-height: 100% !important; */
    margin: 150px 550px !important;
}

.abb-signup-header {
    float: left;
    margin-left: 21%;

}

.ABB_CommonUX_LoginScreen__root>.ABB_CommonUX_LoginScreen__header>.ABB_CommonUX_LoginScreen__logo {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAAAoCAYAAAA8AZ5fAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAL6SURBVHgB7ZuNbdswEIUfAw/gbqBukE5QZYJ6g6oT1Jkg9gbtBuoG7gSxN2gniDJBPEFZ0mYK/0mkKPJRbvgBggHhpGfpdKfjkQIymUwmk8lcI6KPscR0pQ75dLBnaU7zcLBvLbC9gwcS76SLncCLOLFttK76XSrtxnY8S2eM3LgaKmcXx85uQ5TKtgSXQulWantS2g+IR0HSiYazwxVlJNvAiAXHGSydsPRwuPgaxzYGO2eUiA5LJxxODt+nc9zCnWn6GyHmoMDSCYNrhC/Qn89Iy0dwYOkEwdHhwuOixExF+RTpYGmnvMbeWB1uUnOB/ugbUSEzKlwivII/DsO4aPwCB5ZOEDodvk/JYoDTdmPyRClPfgcFlk4YbBE+w/B3VIoqtlGdsBrxYekEw+JwEaDSFuy03qio82rtjlQnKK0ON2PvEsO55Y7J5YrT52bphKUrwkuEYwYaYq4mPJ7MA/sf6ISlw+EiYJ9YsJswhdJ8JDijIOkEY3Jp576ylj9gZ/16hA19TpUCtxarJZxxsi3VVnseG0BnfExa9uvJD5fuWmM2F0q1rcClUA/aXD1o3xAXls5gLi6A0O8mOHXXLi2AaEUNYV7ed57Nf2GCBXmnnLFm64yRs3e4elJ1gVUgPEXCGTRW0UgsTv24VLTF/NOJbgirFyBStpKdOHK4aaVGrKjp1forBTgUGDmnER47AkewMOJtc+JwSgSmiPItOLB0vPnn8ICtVAspFkbI36DA0vHnMMJLcNDOZhdveW7ccNB4saVz+UGNMdsuaHFkuXtPi0e0o7Vq8KjBocbI2UW4QzpvOpx9hmk+PHdYMBdG/Ozz369AZxAmpd9U3WY+qzpEbTGoEB9VREnGAgyWzmCMw6Wtcvbogf9ZWwwITQp5T5obv7+WufEb+6pUufG5mMRpXUfcF8LyI5ZOMHSEVxabGt5Y03qMNLgxkxg14sLSCcpk73O56bBZwxud1jtn0kJ8taGbHc/mM95VxNkqlk4mk8lkMpnMGX8BcsX0a8zt2BkAAAAASUVORK5CYII=) !important;
    /* background-size: auto; */
    height: 60px;
    position: relative;
    width: 240px;
    /* float: right; */
    max-width: 240px;
}

@media (max-width: 1440px) {
    .signup-container-box {
        width: 300px;
        /* max-height: 100% !important; */
        margin: 50px 260px !important;
    }

    .abb-signup-header {
        float: left;
        margin-left: 21%;

    }
}